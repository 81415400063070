<template>
    <div class="page-ryProbe-info">
        <div class="info-header">
            <div class="info-warn" v-if="info.repetition"><span>ID可能存在重复烧录的情况</span> <span>{{ info.updateAt | getYMD }}</span></div>
            <div class="info-warn" v-if="!info.repetition"><span>质检时间</span> <span>{{ info.updateAt | getYMD }}</span></div>
            <div class="info-id">{{info._probeId}}</div>
            <div :class="{'info-status': true, 'pass': info.passed}">
                <span v-if="!info.passed">质检未通过</span>
                <span v-else>质检通过</span>
                <Button type="danger" @click="resetCheck">重新检测</Button>
            </div>
        </div>
        <div class="info-body">
            <div class="info-content">
                <div class="info-content-title">出厂质检项：</div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item) in failArr" :key="item.data">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item) in passArr" :key="item.data">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Toast, Icon, Dialog } from "vant";
import { getYMD } from '@/utils/time'
export default {
    name: "RyProbeInfo",
    components: {
        Button,
        Icon
    },
    filters: {
        getYMD
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            name: "",
            info: {},
            passArr: [],
            failArr: []
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        vm.name = name;
        if (!name) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return;
        }
        vm.loadInfo();
        vm.tt = Toast.loading({
            message: "加载中...",
            forbidClick: true,
            overlay: true,
            duration: 0
        });
    },
    methods: {
        loadInfo() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}probe`,
                params: {
                    probeId: vm.$route.query.uuid
                },
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                res.data._probeId =vm.fomatId(res.data.probeId)
                vm.info = res.data;
                vm.passArr = res.data.content.filter(item => item.state);
                vm.failArr = res.data.content.filter(item => !item.state);
                vm.tt.clear();
            });
        },
        fomatId(probeId) {
            let _probeId = '';
            _probeId += probeId.slice(0, 4);
            _probeId += '    ';
            _probeId += probeId.slice(4, 8);
            _probeId += '    ';
            _probeId += probeId.slice(8, 12);
            _probeId += '    ';
            _probeId += probeId.slice(12, 16);
            return _probeId;
        },
        resetCheck() {
            const vm = this
            Dialog.confirm({
                title: "提示",
                message: "点击”确定“后会将当前检测结果清空，以便重新检测",
                confirmButtonColor: "#256FD9"
            })
                .then(() => {
                    const { USER_NAME } = vm.$config.keys;
                    const name = vm.$localStorage.getItem(USER_NAME);
                    vm.tt = Toast.loading({
                        message: "加载中...",
                        forbidClick: true,
                        overlay: true,
                        duration: 0
                    });
                    vm.$http({
                        method: "put",
                        url: `${vm.$config.base.PROBE_BASE_URL}probe`,
                        data: {
                            name,
                            probeId: vm.$route.query.uuid,
                            password: vm.$config.base.password
                        },
                        except: true
                    }).then(res => {
                        if (res.code != 1) {
                            Toast("网络错误");
                            return;
                        }
                        vm.tt.clear();
                        Toast.success({
                            message: "该条记录已删除，可重新对该设备进行出厂检测~",
                            forbidClick: true,
                            overlay: true,
                            duration: 3000,
                            onClose() {
                                vm.$router.replace({
                                    path: "/ryProbe/list"
                                });
                            }
                        });
                    });
                })
                .catch(() => {
                    // on cancel
                });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-ryProbe-info {
    width: 100%;
    height: 100%;

    .info-header {
        padding: 20px 10px 5px;
        .info-warn {
            box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            line-height: 0.4rem;
            font-size: 0.14rem;
            color: #FB3D66;
            padding: 0 8px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .info-id {
            font-size: 0.28rem;
            letter-spacing: 0.02rem;
        }
        .info-status {
            position: relative;
            padding-left: 0.2rem;
            font-size: 0.2rem;
            font-weight: 600;
            color: #FB3D66;
            margin-top: 0.1rem;
            &.pass {
                color: #256FD9;
                &:after {
                    background: #256FD9;
                }
            }
            &:after {
                content: ' ';
                display: block;
                width: 0.14rem;
                height: 0.14rem;
                border-radius: 0.07rem;
                background: #FB3D66;
                position: absolute;
                left: 0;
                top: 0.07rem;
            }
            button {
                float: right;
                background: #FB3D66;
                height: 35px;
                font-weight: normal;
            }
        }
    }
    .info-body {
        padding: 10px;
        font-size: 0.16rem;
        .info-content {
            padding: 20px 10px;
            border-radius: 9px;
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
            .info-content-item {
                padding-top: 20px;
                padding-right: 0.75rem;
                position: relative;
                padding-left: 0.3rem;
                i {
                    position: absolute;
                    left: 0px;
                    font-size: 0.2rem;
                    color: #256FD9;
                    &.van-icon-clear {
                        color: #FB3D66;
                    }
                }

                span {
                    position: absolute;
                    right: 0;
                    color: #FB3D66;
                    max-width: 200px;
                }

                &.pass {
                    color: #858585;
                    span {
                        color: #858585;
                    }
                }
            }
        }
    }
}
</style>


